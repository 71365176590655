@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

